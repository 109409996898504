<template>
    <div class="cosmetics-detail">
        <!-- banner -->
        <mall-banner
            router-name="前往摄影器材专区"
            router-path="/equipment">
        </mall-banner>
        <!-- 内容部分 -->
        <div class="container">
            <!-- 简介 -->
            <div class="cosmetics-detail-brief">
                <!-- 图片 -->
                <div class="cosmetics-detail-brief-pic">
                    <detail-pic></detail-pic>
                </div>
                <!-- 表单 -->
                <div class="cosmetics-detail-brief-form">
                    <detail-form></detail-form>
                </div>
            </div>
            <!-- 标签页 -->
            <div class="cosmetics-detail-tab">
                <!-- 标签列表 -->
                <div class="cosmetics-detail-tab-list">
                    <a href="javascript:;"
                        v-for="item in tabs_list"
                        :key="item.id"
                        @click="handleTabs(item.value)"
                        :class="{
                            'selected-tab': componentId == item.value
                        }">
                        {{item.label}}
                    </a>
                </div>
                <!-- 标签视图 -->
                <div class="cosmetics-detail-tab-view">
                    <component :is="componentId"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                componentId: "detailTabComment",
                tabs_list: [
                    {
                        value: "detailTabDetail",
                        label: "商品详情",
                    },
                    {
                        value: "detailTabComment",
                        label: "评论",
                    }
                ]
            }
        },
        components: {
            mallBanner: () => import("@/components/imageMall/mallBanner"),
            detailPic: () => import("@/components/imageMall/detailPic"),
            detailForm: () => import("@/components/imageMall/detailForm"),
            detailTabDetail: () => import("@/components/imageMall/detailTabDetail"),
            detailTabComment: () => import("@/components/imageMall/detailTabComment"),
        },
        methods: {
            handleTabs(val){
                this.componentId = val;
            }
        },
    }
</script>

<style scoped>
.cosmetics-detail{
    --color: #fca29a;
    --color2: #fee;
    --color3: #fcb2ab;
    padding-bottom: 50px;
    background-color: #fafafa;
}
/* 简介 */
.cosmetics-detail-brief{
    display: flex;
    align-items: stretch;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 6px;
}
/* 图片 */
.cosmetics-detail-brief-pic{
    width: 40%;
    flex-shrink: 0;
}
/* 表单 */
.cosmetics-detail-brief-form{
    flex: 1 1;
}

/* 标签页 */
.cosmetics-detail-tab{
    margin-top: 30px;
}
/* 标签列表 */
.cosmetics-detail-tab-list > a{
    display: inline-block;
    vertical-align: middle;
    width: 140px;
    margin-left: 10px;
    padding: 10px 0;
    background-color: var(--color);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
    letter-spacing: 2px;
    font-size: 16px;
    color: #fff;
}
.cosmetics-detail-tab-list > a.selected-tab{
    background-color: #fff;
    color: var(--color);
}
/* 标签视图 */
.cosmetics-detail-tab-view{
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
}
</style>